import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';
import { Exercise, SummaryTimelineData, TimelineScore } from '../../../models';
import { Settings } from '../../../models/shared/settings.model';
import { DatePipe } from '../../../pipes';
import {
  ExerciseService,
  IntervalService,
  PreferenceService,
  SettingsService,
  SummaryTimelineService,
} from '../../../services';
import {
  ChartBorderColorConfig,
  ChartColorConfigurator,
  SUMMARY_TIMELINE_CHART_OPTIONS,
} from '../shared';

@UntilDestroy()
@Component({
  selector: 'isa-summary-timeline',
  templateUrl: './summary-timeline.component.html',
  styleUrls: ['./summary-timeline.component.scss'],
})
export class SummaryTimelineComponent implements OnInit, AfterViewInit {
  @ViewChild('scoringChart') scoringChart: BaseChartDirective;

  exerciseId: string;
  chartLabels: string[] = [];
  chartData: any = [];
  isDataLoaded = false;
  loading = true;
  lineChartOptions;
  lineChartColors: Array<ChartBorderColorConfig> =
    ChartColorConfigurator.SUMMARY_TIMELINE_CHART_COLORS;
  chartHeight: number;
  private timer: Subscription;

  constructor(
    private summaryTimelineService: SummaryTimelineService,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit() {
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.exerciseId = exercise.id;
        this.processData(this.exerciseId);
      });

    this.preferenceService
      .getPreferences()
      .pipe(first())
      .subscribe((pref) => {
        this.lineChartOptions = SUMMARY_TIMELINE_CHART_OPTIONS(pref.isLightTheme);
      });
  }

  ngAfterViewInit() {
    const contentElement = <HTMLElement>document.getElementsByClassName('isa-content-body')[0];
    if (contentElement) {
      this.chartHeight = contentElement.offsetHeight - 165;
      this.cdr.detectChanges();
    }
  }

  processData(exerciseId: string): void {
    this.isDataLoaded = false;
    if (exerciseId) {
      this.summaryTimelineService
        .getSummaryTimelineData(exerciseId)
        .pipe(take(1))
        .subscribe((data) => {
          if (data.length) {
            this.setLineChartLabels(data);
            this.setLineChartData(data);
            this.updateChart();
            this.isDataLoaded = true;
          }
          this.loading = false;
          this.getLatestScoringSummaryTimeLineDataItem(exerciseId);
        });
    }
  }

  getLatestScoringSummaryTimeLineDataItem(exerciseId: string): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.timer = this.settingsService
      .getSettings(true)
      .pipe(
        switchMap((settings: Settings) => {
          return this.intervalService.getIntervalWithDelay(
            settings.gamenetSettings.scoringTimelineWidgetRefreshInterval
          );
        }),
        switchMap(() => this.summaryTimelineService.getSummaryTimelineLatestData(exerciseId)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (data.length && data[0].scores.length && !this.dataItemAlreadyExists(data)) {
          this.addLatestTimeLabel(data);
          this.addLatestChartValue(data);
          this.updateChart();
        } else if (data.length && data[0].scores.length && this.isFirstChartLabelWithEndexWord()) {
          this.updateLatestEndexScoreIfChanged(data);
        }
      });
  }

  dataItemAlreadyExists(data: SummaryTimelineData[]): boolean {
    if (data[0].scores[0].isEndex) {
      return this.isFirstChartLabelWithEndexWord();
    }

    return (
      this.chartLabels.length > 0 &&
      this.chartLabels[this.chartLabels.length - 1] ===
        this.datePipe.transform(data[0].scores[0].timestamp, 'short')
    );
  }

  private isFirstChartLabelWithEndexWord(): boolean {
    return (
      this.chartLabels.length > 0 && this.chartLabels[this.chartLabels.length - 1].includes('ENDEX')
    );
  }

  updateLatestEndexScoreIfChanged(data: SummaryTimelineData[]): void {
    data.map((item) => {
      this.chartData.map((teamItem) => {
        if (
          item.teamName.toUpperCase() === teamItem.label &&
          item.scores[0].value !== teamItem.data[teamItem.data.length - 2]
        ) {
          teamItem.data[teamItem.data.length - 2] = item.scores[0].value;
          this.updateChart();
        }
      });
    });
  }

  setLineChartLabels(data: SummaryTimelineData[]): void {
    this.chartLabels = [];
    data[0].scores.forEach((score) => {
      this.chartLabels.push(this.datePipe.transform(score.timestamp, 'short'));
      if (score.isEndex) {
        this.chartLabels.push('ENDEX ' + this.datePipe.transform(score.timestamp, 'shortTime'));
      }
    });
  }

  addLatestTimeLabel(data: SummaryTimelineData[]): void {
    if (data) {
      this.chartLabels.push(this.datePipe.transform(data[0].scores[0].timestamp, 'short'));
      if (data[0].scores[0].isEndex) {
        this.chartLabels.push(
          'ENDEX ' + this.datePipe.transform(data[0].scores[0].timestamp, 'shortTime')
        );
      }
    }
  }

  addLatestChartValue(data: SummaryTimelineData[]): void {
    if (data) {
      data.map((item) => {
        this.chartData.map((teamItem) => {
          if (item.teamName.toUpperCase() === teamItem.label) {
            teamItem.data.push(item.scores[0].value);
            if (item.scores[0].isEndex) {
              teamItem.data.push(null);
            }
          }
        });
      });
    }
  }

  setLineChartData(data: SummaryTimelineData[]): void {
    this.chartData = [];
    data.map((item, index) => {
      this.chartData.push({
        data: this.addChartDataValues(item.scores),
        label: item.teamName.toUpperCase(),
        backgroundColor: this.lineChartColors[index]?.backgroundColor,
        borderColor: this.lineChartColors[index]?.borderColor,
      });
    });
  }

  addChartDataValues(scores: TimelineScore[]): any {
    const result = [];
    scores.forEach((score) => {
      result.push(score.value);
      if (score.isEndex) {
        result.push(null);
      }
    });

    return result;
  }

  updateChart(): void {
    if (this.scoringChart && this.scoringChart.chart) {
      this.scoringChart.chart.update();
      this.scoringChart.chart.config.data.labels = this.chartLabels;
    }
  }
}
