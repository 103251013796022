import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import {
  AttackCampaignDataObjective,
  AttackCampaignDataObjectiveTeam,
  AttackCampaignReport,
} from '../../../../models';
import { AttackCampaignService } from '../../../../services';
import { OBJECTIVE_STATUS, QuillEditorUtil, TagValidator } from '../../../../shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-multi-report-dialog',
  templateUrl: './multi-report-dialog.component.html',
  styleUrls: ['./multi-report-dialog.component.scss'],
})
export class MultiReportDialogComponent implements OnInit {
  filteredTeams: AttackCampaignDataObjectiveTeam[];
  form: UntypedFormGroup;
  OBJECTIVE_STATUS = OBJECTIVE_STATUS;
  isSubmitting = false;
  isSubmitted = false;
  readonly STATUS_CTRL = 'status';
  readonly EVIDENCE_CTRL = 'evidence';

  get imageUploadUrl(): string {
    return this.attackCampaignService.getImageUploadUrl(this.data.exerciseId);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MultiReportInput,
    private dialogRef: MatDialogRef<MultiReportDialogComponent, MultiReportResult>,
    private attackCampaignService: AttackCampaignService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private tagValidator: TagValidator
  ) {}

  ngOnInit() {
    this.filteredTeams = this.data.objective.teams.filter(
      (team) => team.objectiveUserName === this.data.currentUsername
    );

    this.form = new UntypedFormGroup({});
    this.filteredTeams.forEach((team) => {
      this.form.addControl(
        team.teamId,
        new UntypedFormGroup({
          [this.STATUS_CTRL]: new UntypedFormControl('', Validators.required),
          [this.EVIDENCE_CTRL]: new UntypedFormControl(
            this.data.objective.reportTemplate ? this.data.objective.reportTemplate : ''
          ),
        })
      );
    });
  }

  report() {
    this.isSubmitted = true;
    if (this.isSubmitting || this.form.invalid || !this.areAllTagsProcessed()) return;

    this.isSubmitting = true;
    const reports = this.filteredTeams.map((team) => {
      const teamCtrl = this.form.get(team.teamId);
      return <AttackCampaignReport>{
        objectiveContainerId: team.objectiveContainerId,
        objectiveId: team.objectiveId,
        teamId: team.teamId,
        exerciseId: this.data.exerciseId,
        status: teamCtrl.get(this.STATUS_CTRL).value,
        evidence: teamCtrl.get(this.EVIDENCE_CTRL).value,
      };
    });
    this.attackCampaignService
      .createReports(reports)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: () => {
          this.notificationsService.success('ui.attackCampaign.reportsSubmitted');
          this.dialogRef.close({ reports });
        },
        error: () => {
          this.notificationsService.error('ui.attackCampaign.submitError');
        },
      });
  }

  areAllTagsProcessed(): boolean {
    const notProcessedTagsPerTeam = this.filteredTeams
      .map((team) => {
        const evidence = this.form.get(team.teamId).get(this.EVIDENCE_CTRL).value;
        const notProcessedTags = this.tagValidator.getNotProcessedTags(evidence);

        return notProcessedTags != null ? `${team.teamName}: ${notProcessedTags}` : null;
      })
      .filter((it) => it != null);

    if (notProcessedTagsPerTeam.length === 0) return true;

    this.notificationsService.warn(
      TagValidator.NOT_ALL_TAGS_PROCESSED_MSG,
      notProcessedTagsPerTeam.join('\n')
    );

    return false;
  }

  addQuillImageHandler(editorInstance: any) {
    QuillEditorUtil.addImageHandler(editorInstance, this.imageUploadUrl);
  }

  setStatusForAll(status: string) {
    this.filteredTeams.forEach((team) => {
      const teamStatusCtrl = this.getStatusCtrl(team.teamId);
      if (teamStatusCtrl) {
        teamStatusCtrl.setValue(status);
      }
    });
  }

  setTextForAll(teamId: string) {
    const evidence = this.form.get(teamId)?.get(this.EVIDENCE_CTRL)?.value;

    if (evidence) {
      this.filteredTeams.forEach((team) => {
        this.form.get(team.teamId)?.get(this.EVIDENCE_CTRL)?.setValue(evidence);
      });
      this.notificationsService.success('ui.attackCampaign.setReportTextsSuccess');
    } else {
      this.notificationsService.error('ui.attackCampaign.setReportTextsError');
    }
  }

  getStatusLabel(status: string) {
    switch (status) {
      case OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE:
        return this.translate.instant('ui.attackCampaign.unsuccessful');
      case OBJECTIVE_STATUS.COMPROMISED:
        return this.translate.instant('ui.attackCampaign.successful');
      default:
        return '';
    }
  }

  getStatusCtrl(teamId: string): AbstractControl {
    return this.form.get(`${teamId}.${this.STATUS_CTRL}`);
  }
}

export interface MultiReportInput {
  objective: AttackCampaignDataObjective;
  currentUsername: string;
  exerciseId: string;
}

export interface MultiReportResult {
  reports: AttackCampaignReport[];
}
