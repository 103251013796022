import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SummaryTimelineData } from '../../models';
import { TeamNamePipe } from '../../pipes';

@Injectable()
export class SummaryTimelineService {
  constructor(
    private http: HttpClient,
    private teamNamePipe: TeamNamePipe
  ) {}

  getSummaryTimelineData(exerciseId: string): Observable<SummaryTimelineData[]> {
    return this.http
      .get<any[]>(`api/widget/summary-timeline/exercise/${exerciseId}`)
      .pipe(map((dataArray) => this.convertToModels(dataArray)));
  }

  getSummaryTimelineLatestData(exerciseId: string): Observable<SummaryTimelineData[]> {
    return this.http
      .get<any[]>(`api/widget/summary-timeline/exercise/${exerciseId}/latest`)
      .pipe(map((dataArray) => this.convertToModels(dataArray)));
  }

  private convertToModels(dataArray: any[]) {
    const data = dataArray.map((data) => new SummaryTimelineData(data));
    data.forEach((it) => (it.teamName = this.teamNamePipe.transform(it.teamName)));
    return data;
  }
}
