import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamTimelineData } from '../../models';

@Injectable()
export class TeamTimelineService {
  constructor(private http: HttpClient) {}

  getTeamTimelineData(exerciseId: string, teamId: string): Observable<TeamTimelineData[]> {
    return this.http
      .get<TeamTimelineData[]>(`api/widget/team-timeline/exercise/${exerciseId}/team/${teamId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new TeamTimelineData(data));
        })
      );
  }

  getTeamTimelineLatestData(exerciseId: string, teamId: string): Observable<TeamTimelineData[]> {
    return this.http
      .get<
        TeamTimelineData[]
      >(`api/widget/team-timeline/exercise/${exerciseId}/team/${teamId}/latest`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new TeamTimelineData(data));
        })
      );
  }
}
